@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
html, body {
  background: black;
  color: white;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  min-height: 100dvh;
}

/* Message container spacing */
.message-container {
  padding-top: 4rem;
}

/* Desktop styles */
@media (min-width: 769px) {
  .message-container {
    padding-top: 6rem;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  html, body, #root {
    height: auto;
    position: static;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

/* Hide scrollbars but keep functionality */
::-webkit-scrollbar {
  display: none;
}

/* For Safari */
@supports (-webkit-touch-callout: none) {
  .message-container {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.invisible-recaptcha {
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0.01;
  pointer-events: none;
  transform: scale(0.9);
}
